import React from "react"
import Button from "@common/Button/Button"
import { MAIN_BUTTON } from "@constants"
import arrowIcon from "@images/common/ic-arrow-right.svg"
import AgreementCheckbox from "../../../../ContactUs/components/AgreementCheckbox/AgreementCheckbox"
import CVDropzone from "../../../../ContactUs/components/CVDropzone/CVDropzone"
import { useFormConfiguration } from "../hooks"

import {
  EMAIL_INPUT_NAME,
  EMAIL_INPUT_PLACEHOLDER,
  EMAIL_INPUT_TYPE,
  FULL_NAME_INPUT_NAME,
  FULL_NAME_INPUT_PLACEHOLDER,
  FULL_NAME_INPUT_TYPE,
  MOBILE_PHONE_INPUT_NAME,
  MOBILE_PHONE_INPUT_TYPE,
  MOBILE_PHONE_INPUT_PLACEHOLDER,
  SEND_BUTTON_TEXT,
  NOTE_INPUT_PLACEHOLDER,
  NOTE_INPUT_NAME,
} from "../constants"
import {
  StyledFormWrapper,
  StyledInputsContainer,
  StyledFormBottomContainer,
} from "./styled"
import { StyledInputError } from "./components/Input/styled"
import { TextArea, Input } from "./components/Input"

const Form = ({ vacancyId, setShowMessage }) => {
  const {
    fullName,
    email,
    phone,
    note,
    cvState,
    isDisabled,
    isCustomFlow,
    entryValidator,
    checkValidation,
    validationErrors,
    cvSubmissionError,
    setAgreement,
    setCvState,
    handleForm,
  } = useFormConfiguration({ vacancyId, setShowMessage })

  return (
    <StyledFormWrapper>
      <StyledInputsContainer>
        <Input
          name={FULL_NAME_INPUT_NAME}
          type={FULL_NAME_INPUT_TYPE}
          placeholder={FULL_NAME_INPUT_PLACEHOLDER}
          inputValue={fullName}
          handleInput={entryValidator.fullNameValidator}
          checkValidation={checkValidation}
          validationErrors={validationErrors}
        />
        <Input
          name={EMAIL_INPUT_NAME}
          type={EMAIL_INPUT_TYPE}
          placeholder={EMAIL_INPUT_PLACEHOLDER}
          inputValue={email}
          handleInput={entryValidator.emailValidator}
          checkValidation={checkValidation}
          validationErrors={validationErrors}
        />
        <Input
          name={MOBILE_PHONE_INPUT_NAME}
          type={MOBILE_PHONE_INPUT_TYPE}
          placeholder={MOBILE_PHONE_INPUT_PLACEHOLDER}
          inputValue={phone}
          handleInput={entryValidator.phoneValidator}
          checkValidation={checkValidation}
          validationErrors={validationErrors}
        />
        {isCustomFlow && (
          <TextArea
            rows={8}
            name={NOTE_INPUT_NAME}
            placeholder={NOTE_INPUT_PLACEHOLDER}
            inputValue={note}
            handleInput={entryValidator.noteValidator}
            checkValidation={checkValidation}
            validationErrors={validationErrors}
          />
        )}
        <CVDropzone setState={setCvState} state={cvState} />
        <StyledInputError>{cvSubmissionError}</StyledInputError>
      </StyledInputsContainer>

      <StyledFormBottomContainer>
        <AgreementCheckbox
          handleChange={() => setAgreement(prevState => !prevState)}
        />
        <Button
          text={SEND_BUTTON_TEXT}
          svgPath={arrowIcon}
          handleClick={handleForm}
          type={MAIN_BUTTON}
          isDisabled={isDisabled}
        />
      </StyledFormBottomContainer>
    </StyledFormWrapper>
  )
}

export default Form
