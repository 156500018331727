export const FULL_NAME_INPUT_NAME = "fullName"
export const EMAIL_INPUT_NAME = "email"
export const MOBILE_PHONE_INPUT_NAME = "phone"
export const NOTE_INPUT_NAME = "note"

export const FULL_NAME_INPUT_TYPE = "text"
export const EMAIL_INPUT_TYPE = "email"
export const MOBILE_PHONE_INPUT_TYPE = "tel"
export const NOTE_INPUT_TYPE = "text"

export const FULL_NAME_INPUT_PLACEHOLDER = "Full name"
export const EMAIL_INPUT_PLACEHOLDER = "Email"
export const MOBILE_PHONE_INPUT_PLACEHOLDER = "Phone number"
export const NOTE_INPUT_PLACEHOLDER = "Cover letter"

export const FULL_NAME_VALIDATION_ERROR = "First and last name are required."
export const EMAIL_VALIDATION_ERROR =
  "Hmmm, you sure your email spelled correctly?"
export const MOBILE_PHONE_VALIDATION_ERROR = "Phone number is required."
export const NOTE_VALIDATION_ERROR = "Cover letter is required."
export const LATIN_CHARACTERS_VALIDATION_ERROR =
  "Please use the Latin Alphabet."
export const DIGITS_VALIDATION_ERROR = "Only digits are allowed."

export const SEND_BUTTON_TEXT = "Send"
