import { useState } from "react"
import { EMAIL_VALIDATION_REGEXP } from "@constants"
import SendCV from "../../../../../../utils/sendCV"
import {
  DIGITS_VALIDATION_ERROR,
  EMAIL_VALIDATION_ERROR,
  FULL_NAME_VALIDATION_ERROR,
  LATIN_CHARACTERS_VALIDATION_ERROR,
  MOBILE_PHONE_VALIDATION_ERROR,
  NOTE_VALIDATION_ERROR,
} from "../../constants"

const VACANCY_ID_WITH_CUSTOM_FLOW = 116494

const useFormConfiguration = ({ vacancyId, setShowMessage }) => {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [note, setNote] = useState("")
  const [cvState, setCvState] = useState({
    cv: null,
    showErrorMessageForCV: false,
  })
  const [agreement, setAgreement] = useState(false)
  const isCustomFlow = vacancyId === VACANCY_ID_WITH_CUSTOM_FLOW
  const isCVRequired = !isCustomFlow
  const isNoteRequired = isCustomFlow

  const [validationChecks, setValidationChecks] = useState({
    fullName: false,
    email: false,
    phone: false,
    note: !isNoteRequired,
  })

  const [validationErrors, setValidationErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
    note: "",
  })
  const [cvSubmissionError, setCvSubmissionError] = useState("")

  const clearForm = () => {
    setFullName("")
    setEmail("")
    setPhone("")
    setNote("")
    setCvState({ cv: null, showErrorMessageForCV: false })
    setAgreement(false)
  }

  const checkValidation = {
    fullName: () => {
      if (!/[a-zA-Z]+\s+[a-zA-Z]+/g.test(fullName)) {
        setValidationChecks({ ...validationChecks, fullName: false })
        setValidationErrors({
          ...validationErrors,
          fullName: FULL_NAME_VALIDATION_ERROR,
        })
      }
    },
    email: () => {
      if (!EMAIL_VALIDATION_REGEXP.test(email)) {
        setValidationChecks({ ...validationChecks, email: false })
        setValidationErrors({
          ...validationErrors,
          email: EMAIL_VALIDATION_ERROR,
        })
      }
    },
    phone: () => {
      if (phone.length < 1) {
        setValidationChecks({ ...validationChecks, phone: false })
        setValidationErrors({
          ...validationErrors,
          phone: MOBILE_PHONE_VALIDATION_ERROR,
        })
      }
    },
    note: () => {
      if (isNoteRequired && note.length < 1) {
        setValidationChecks({ ...validationChecks, note: false })
        setValidationErrors({
          ...validationErrors,
          note: NOTE_VALIDATION_ERROR,
        })
      }
    },
  }

  const entryValidator = {
    fullNameValidator: value => {
      if (/^[a-zA-Z ]*$/.test(value)) {
        setFullName(value)
        setValidationErrors({ ...validationErrors, fullName: "" })
        if (/[a-zA-Z]+\s+[a-zA-Z]+/g.test(value)) {
          setValidationChecks({ ...validationChecks, fullName: true })
          setValidationErrors({ ...validationErrors, fullName: "" })
        } else {
          setValidationChecks({ ...validationChecks, fullName: false })
        }

        if (value.length === 0) {
          setValidationErrors({
            ...validationErrors,
            fullName: FULL_NAME_VALIDATION_ERROR,
          })
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          fullName: LATIN_CHARACTERS_VALIDATION_ERROR,
        })
      }
    },

    emailValidator: value => {
      if (/^[a-zA-Z0-9@.]*$/.test(value)) {
        setEmail(value)
        setValidationErrors({ ...validationErrors, email: "" })

        if (EMAIL_VALIDATION_REGEXP.test(value)) {
          setValidationChecks({ ...validationChecks, email: true })
        } else {
          setValidationChecks({ ...validationChecks, email: false })
        }

        if (value.length === 0) {
          setValidationErrors({
            ...validationErrors,
            email: EMAIL_VALIDATION_ERROR,
          })
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          email: LATIN_CHARACTERS_VALIDATION_ERROR,
        })
      }
    },

    phoneValidator: value => {
      if (/^[0-9]*$/.test(value)) {
        setPhone(value)
        setValidationErrors({ ...validationErrors, phone: "" })

        if (value.length > 0) {
          setValidationChecks({ ...validationChecks, phone: true })
        } else {
          setValidationChecks({ ...validationChecks, phone: false })
          setValidationErrors({
            ...validationErrors,
            phone: MOBILE_PHONE_VALIDATION_ERROR,
          })
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          phone: DIGITS_VALIDATION_ERROR,
        })
      }
    },

    noteValidator: value => {
      setNote(value)

      // Only validate note content if it's required (for custom flow)
      if (isNoteRequired) {
        if (value.length > 0) {
          setValidationErrors({ ...validationErrors, note: "" })
          setValidationChecks({ ...validationChecks, note: true })
        } else {
          setValidationChecks({ ...validationChecks, note: false })
          setValidationErrors({
            ...validationErrors,
            note: NOTE_VALIDATION_ERROR,
          })
        }
      } else {
        setValidationChecks({ ...validationChecks, note: true })
        setValidationErrors({ ...validationErrors, note: "" })
      }
    },
  }

  const isDisabled = !Object.values({ ...validationChecks, agreement }).every(
    check => check
  )

  const handleForm = async () => {
    if (!isDisabled) {
      if (isCVRequired && cvState.cv === null) {
        setCvState({ ...cvState, showErrorMessageForCV: true })
        return
      }

      const { isSendCVError, message } = await SendCV.sendCV(
        { fullName, email, phone, vacancyId, agreement, note, ...cvState },
        () => {
          clearForm()
          setShowMessage(true)
        }
      )
      if (isSendCVError) setCvSubmissionError(message)
    }
  }

  return {
    fullName,
    email,
    phone,
    note,
    cvState,
    isDisabled,
    isCustomFlow,
    entryValidator,
    checkValidation,
    validationErrors,
    cvSubmissionError,
    setAgreement,
    setCvState,
    handleForm,
    isCustomFlow,
    isCVRequired,
    isNoteRequired,
  }
}

export default useFormConfiguration
