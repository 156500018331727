import React from "react"
import {
  StyledInputContainer,
  StyledInput,
  StyledInputError,
  StyledITextArea,
} from "./styled"

export const Input = ({
  name,
  type,
  placeholder,
  inputValue,
  handleInput,
  checkValidation,
  validationErrors,
}) => {
  return (
    <StyledInputContainer>
      <StyledInput
        type={type}
        placeholder={placeholder}
        value={inputValue}
        onChange={({ currentTarget: { value } }) => handleInput(value)}
        onBlur={() => checkValidation[name]()}
        hasError={!!validationErrors[name]}
      />
      {!!validationErrors[name] && (
        <StyledInputError>{validationErrors[name]}</StyledInputError>
      )}
    </StyledInputContainer>
  )
}
export const TextArea = ({
  name,
  type,
  placeholder,
  inputValue,
  handleInput,
  checkValidation,
  validationErrors,
}) => {
  return (
    <StyledInputContainer>
      <StyledITextArea
        rows={8}
        maxLength={5000}
        type={type}
        placeholder={placeholder}
        value={inputValue}
        onChange={({ currentTarget: { value } }) => handleInput(value)}
        onBlur={() => checkValidation[name]()}
        hasError={!!validationErrors[name]}
      />
      {!!validationErrors[name] && (
        <StyledInputError>{validationErrors[name]}</StyledInputError>
      )}
    </StyledInputContainer>
  )
}
